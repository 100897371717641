<template>
	<div id="app">
    <keep-alive>
		  <router-view></router-view>
    </keep-alive>
	</div>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'App',
  created (){
  },
  methods: {
    
  }
}
</script>

<style lang="scss">
@import "./assets/scss/reset.scss";
@import "./assets/scss/layout.scss";
// @import "https://cdn.jsdelivr.net/npm/element-ui@2.15.13/lib/theme-chalk/index.min.css";
::-webkit-scrollbar{
  width: 0px;
}
</style>
