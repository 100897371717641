<template>
  <div class="footer">
    <div class="footer_inner">
      <div class="top_footer">
        <div class="logo">
          <a href="https://www.privateautoquote.com/">
            <img alt src="@/assets/logo_1.png" />
          </a>
        </div>
        <p class="txt">Disclaimer: This is an advertorial and not an actual news article, blog, or consumer protection
          update. Our aim is to bring consumers the best information that may help them make informed decisions. This
          article is for demonstration purposes only. By proceeding forward you automatically agree to our terms and
          conditions. The purpose of this site is the solicitation of insurance.</p>
        <p class="txt">This article is for demonstration purposes only. By proceeding forward you automatically agree to
          our terms and conditions. The purpose of this site is the solicitation of insurance.</p>
      </div>
      <div class="down_border"></div>
      <div class="down_footer">
        <ul>
          <li>
            <a href="/about">About Us</a>
          </li>
          <li>
            <a href="/privacy-statement">Privacy Policy</a>
          </li>
          <li>
            <a href="/terms-of-use">Terms of Use</a>
          </li>
          <li>
            <a href="/terms-of-service">Terms of Service</a>
          </li>
          <li>
            <a href="/dnc-policy">DNC Policy</a>
          </li>
        </ul>
      </div>
      <div class="copyright-txt">
        110 16TH ST DENVER, CO 80202
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'nav-footer',
  data() {
    return {}
  },
  methods: {
    scroll(target) {
      // console.log(event.$el);
      if (this.$route.path != '/privacy-statement') {
        this.$router.push({
          name: 'privacystatement',
          params: {
            target: target
          }
        })
      } else {
        this.scrollIntoView(target)
      }
    },
    scrollIntoView(target) {
      const tragetElem = document.querySelector(target)
      const tragetElemPostition = tragetElem.offsetTop - 105

      // 当前滚动高度
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop
      // 滚动step方法
      const step = function () {
        // 距离目标滚动距离
        const distance = tragetElemPostition - scrollTop

        // 目标需要滚动的距离，也就是只走全部距离的五分之一
        scrollTop = scrollTop + distance / 25
        if (Math.abs(distance) < 1) {
          window.scrollTo(0, tragetElemPostition)
        } else {
          window.scrollTo(0, scrollTop)
          setTimeout(step, 0)
        }
      }
      step()
    }
  }
}
</script>

<style lang="scss">
@import "../assets/scss/layout.scss";

.footer {
  padding: 30px 0px 0px 0px;
  background-color: rgba(56, 56, 56, 1);
  color: #ffffff;

  .footer_inner {
    .top_footer {
      max-width: 800px;
      margin: 0 auto;

      .logo {
        text-align: center;

        img {
          vertical-align: middle;
        }

        .title {
          font-size: 20px;
          font-weight: 700;
          color: rgba(255, 255, 255, 1);
          margin-left: 10px;
        }

        margin-bottom: 10px;
      }

      .txt {
        color: rgba(255, 255, 255, 1);
        font-size: 14px;
        font-weight: 400;
      }
    }

    .down_border {
      border-top: 1px solid rgba(255, 255, 255, 0.1);
      margin-top: 15px;
      margin-bottom: 15px;
    }

    .down_footer {
      width: 100%;
      clear: both;

      ul {
        display: flex;
        flex-direction: row;
        justify-content: center;

        li {
          margin: 0px 10px;
          font-size: 14px;

          a {
            color: rgba(255, 255, 255, 1);
          }
        }
      }

      margin-bottom: 20px;
    }

    .copyright-txt {
      text-align: center;
      font-size: 14px;
      letter-spacing: 0px;
      line-height: 24px;
      text-decoration: none;
      padding-bottom: 20px;
    }
  }

}

@media screen and (max-width: 1160px) {
  .footer {
    .txt {
      padding: 0px 20px;
    }
  }
}

@media screen and (max-width: 767px) {
  .footer {
    .txt {
      padding: 0px 10px;
    }

    .copyright-txt {
      padding: 0px 5px 0px 5px;
    }
  }
}
</style>
