import Vue from "vue";
// import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
import Layout from "../views/Layout.vue";
// const originalPush = VueRouter.prototype.push;
// VueRouter.prototype.push = function push(location) {
//     return originalPush.call(this, location).catch((err) => err);
// };

// Vue.use(VueRouter)

// const routes = [{
//   path: '/',
//   name: 'Home',
//   component: Home,
//   children: [{
//     path: '/',
//     name: 'debt',
//     component: () =>
//       import('../views/debt.vue')
//   }, {
//     path: '/thank-you',
//     name: 'ThankYou',
//     component: () =>
//       import('../views/ThankYou.vue')
//   },
//   {
//     path: '/ProcessFormDetails',
//     name: 'ProcessFormDetails',
//     component: () =>
//       import('../views/ProcessFormDetails.vue')
//   },
//   {
//     path: '/privacy-statement',
//     name: 'privacystatement',
//     component: () =>
//       import('../views/PrivacyStatement.vue')
//   },
//   {
//     path: '/terms-of-use',
//     name: 'termsofuse',
//     component: () =>
//       import('../views/TermsofUse.vue')
//   },
//   {
//     path: '/terms-of-service',
//     name: 'termsofservice',
//     component: () =>
//       import('../views/TermsofService.vue')
//   },
//   {
//     path: '/ccpa-opt-out',
//     name: 'ccpaoptout',
//     component: () =>
//       import('../views/CcpaOptOut.vue')
//   }, {
//     path: '/dnc-policy',
//     name: 'DncPolicy',
//     component: () =>
//       import('../views/DncPolicy.vue')
//   }
//   ]
// }]
const routes = [
  {
    path: "/",
    name: "Layout",
    component: Layout,
    children: [
      {
        path: "/",
        name: "Home",
        component: () => import("../views/Home.vue"),
      },
      {
        path: "/ProcessForm",
        name: "ProcessForm",
        component: () => import("../views/ProcessForm.vue"),
      },
      {
        path: "/About",
        name: "About",
        component: () => import("../views/About.vue"),
      },
      {
        path: "/Result",
        name: "Result",
        component: () => import("../views/Result.vue"),
      },
      {
        path: "/privacy-statement",
        name: "privacystatement",
        component: () => import("../views/PrivacyStatement.vue"),
      },
      {
        path: "/terms-of-use",
        name: "termsofuse",
        component: () => import("../views/TermsofUse.vue"),
      },
      {
        path: "/terms-of-service",
        name: "termsofservice",
        component: () => import("../views/TermsofService.vue"),
      },
      {
        path: "/dnc-policy",
        name: "DncPolicy",
        component: () => import("../views/DncPolicy.vue"),
      },
    ],
  },
];
console.log(VueRouter);
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
