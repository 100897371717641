<template>
  <el-header>
    <div class="grid-content">
      <a href="/">
        <img alt src="@/assets/logo.png" />
      </a>
    </div>
  </el-header>
</template>

<script>
export default {
  name: 'nav-header',
  data() {
    return {
      isActive: true
    }
  },
  methods: {
    scroll(target) {
      // console.log(event.$el);
      if (this.$route.path != '/') {
        this.$router.push({
          name: 'debt',
          params: {
            target: target
          }
        })
        // console.log(this.$route);
      } else {
        this.scrollIntoView(target)
      }
    },
    scrollIntoView(target) {
      const tragetElem = document.querySelector(target)
      const tragetElemPostition = tragetElem.offsetTop - 105

      // 当前滚动高度
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop
      // 滚动step方法
      const step = function () {
        // 距离目标滚动距离
        const distance = tragetElemPostition - scrollTop

        // 目标需要滚动的距离，也就是只走全部距离的五分之一
        scrollTop = scrollTop + distance / 25
        if (Math.abs(distance) < 1) {
          window.scrollTo(0, tragetElemPostition)
        } else {
          window.scrollTo(0, scrollTop)
          setTimeout(step, 0)
        }
      }
      step()
    }
  },
  mounted() {
    //   //获取页面可视化高度
    //  window.addEventListener("scroll",()=>{
    //   //获取页面滚动的高度
    //      let scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
    //      if(scrollTop > 200){
    //          this.flag = true;
    //      }else{
    //          this.flag = false;
    //      }
    //  })
  },
  watch: {
    $route(to, from) {
      if (to.path == '/ProcessFormDetails') {
        this.isActive = false
      } else {
        this.isActive = true
      }
      console.log(to.path)
    }
  }
}
</script>

<style lang="scss">
@import "../assets/scss/layout.scss";

.el-header {
  width: 100%;
  height: auto !important;
  padding: 10px 0 10px 0;
  box-sizing: border-box;
  border-bottom: 1px solid #f3f3f4;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
  text-align: center;

  img {
    vertical-align: middle;
  }
}

@media screen and (max-width: 992px) {
  .el-header {
    padding: 20px 0;

    .logo {
      justify-content: center;
    }
  }
}

@media screen and (max-width: 820px) {}

@media screen and (max-width: 768px) {
  .el-header {
    padding: 10px;

    .logo {
      img {
        width: 160px;
      }
    }

    .hidden-sm-and-down {
      display: none;
    }
  }
}

@media screen and (max-width: 390px) {
  .header {}
}
</style>
